<template>
  <div class="official">
    <div class="header-nav" :style="topNavBg">
      <div class="header-logo">
        <img class="header-img" src="../../assets/yiben_logo.png" alt="" />
      </div>
      <div class="nav">
        <ul class="header-ul">
          <li
            @mouseenter="navMouse(item)"
            class="header-li"
            v-for="item in navigationData"
            :key="item.id"
            @click="goSubPage(item)"
            :class="{ active: item.ljdz === routerNow }"
          >
            {{ item.name }}
            <div v-if="item.child.length !== 0">
              <i v-if="downCode == false" class="el-icon-arrow-down"></i
              ><i v-if="downCode == true" class="el-icon-arrow-up"></i>
            </div>
          </li>
        </ul>
      </div>
      <div style="font-size: 18px">
        <img
          style="
            width: 24px
            height: 24px;
            margin-bottom: -8px;
            margin-right: 8px;
          "
          src="../../assets/phpone.png"
          alt=""
        />
        全国服务热线: 0816-2606550
      </div>
      <div class="header-down" v-show="downCode" @mouseleave="downCode = false">
        <div class="down-conte">
          <div v-for="(item, index) in childNav" :key="index">
            <div class="down-title">
              <img :src="require(`@/assets/downIcon${index + 1}.png`)" alt="" />
              <div>{{ item.name }}</div>
            </div>
            <div class="line"></div>
            <div
              @click="goThreePage(item)"
              class="down-txt"
              v-for="(item, index) in item.child"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-img">
      <el-carousel
        indicator-position="outside"
        :interval="4000"
        arrow="never"
        @change="handleCarouselChange"
        pause-on-hover
        ref="carousel"
        height="850px"
      >
        <el-carousel-item v-for="(item, index) in carousel" :key="index">
          <img v-if="item.tpdz" style="height: 100%" :src="item.tpdz" alt="" />
          <iframe
            id="videoFrame"
            style="width: 100%; height: 100%"
            v-if="item.videoData"
            :src="item.videoData"
            sandbox=""
          ></iframe>
        </el-carousel-item>
      </el-carousel>
      <div class="carousel-indicators">
        <span
          v-for="(item, index) in carousel"
          :key="index"
          :class="{ active: index + 1 === currentIndex + 1 }"
          @click="goToCarouselItem(index), stopVideo(item)"
        >
          <div class="line" :class="{ active: index + 1 === currentIndex + 1 }">
            <div class="line-color"></div>
          </div>
          <div>0{{ index + 1 }}</div></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      routerNow: "",
      carousel: [],
      currentIndex: 0,
      navigationData: [],
      childNav: [],
      topNavBg: {
        backgroundColor: "",
      },
      icon1: require("@/assets/downIcon1.png"),
      icon2: require("@/assets/downIcon2.png"),
      icon3: require("@/assets/downIcon3.png"),
      icon4: require("@/assets/downIcon4.png"),
      downCode: false,
    };
  },

  mounted() {
    this.getData();
    this.getCarousel();
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
    this.routerNow = this.$route.path;
  },

  methods: {
    //停止播放视频
    stopVideo(item) {
      if (!item.videoData) {
        videoFrame.src = videoFrame.src;
      }
    },

    // 获取轮播图数据
    getCarousel() {
      this.axios
        .get("https://www.myebkj.com/backend/lbLbgl/getAllMh")
        .then((res) => {
          const arr = {
            sort: 2,
            videoData: "https://www.myebkj.com/xcsp.mp4",
          };
          res.data.object.splice(1, 0, arr);
          this.carousel = res.data.object;
        });
    },
    // 轮播点击
    handleCarouselChange(val) {
      this.currentIndex = val;
    },
    goToCarouselItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    // 鼠标移入处理数据
    navMouse(item) {
      if (item.child.length !== 0) {
        this.downCode = true;
        this.childNav = item.child;
      } else {
        this.downCode = false;
      }
    },

    // 点击跳转子页面
    goSubPage(item) {
      this.$router.push(item.ljdz).catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },

    // 跳转三级菜单子页面
    goThreePage(item) {
      this.$router.push(item.ljdz).catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },

    // 获取头部导航栏数据
    getData() {
      this.axios
        .get("https://www.myebkj.com/backend/dhDhgl/getNavigationTree")
        .then((res) => {
          this.navigationData = res.data.object;
        });
    },

    // 跳转首页
    goHome() {
      this.$router.push("/").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    //跳转简介页面
    goIntro() {
      this.$router.push("/intro").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },

    //跳转新闻页面
    goNews() {
      this.$router.push("/news").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },

    // 跳转思政页面
    goPolitical() {
      this.$router.push("/political").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转教育建设内涵
    goConnotation() {
      this.$router.push("/connotation").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },

    // 跳转虚拟仿真实训室页面
    goTraining() {
      this.$router.push("/training").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },

    // 跳转智慧校园页面
    goWisdom() {
      this.$router.push("/wisdom").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转课堂教学平台
    goClassroom() {
      this.$router.push("/classroom").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转运维服务
    goMaintain() {
      this.$router.push("/maintain").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转软件定制服务
    goCustom() {
      this.$router.push("/custom").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },

    // 滚动页面时触发导航变色
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 设置背景颜色的透明度
      if (scrollTop) {
        this.topNavBg.backgroundColor = `rgba(36, 38, 50,
	        	${scrollTop / (scrollTop + 50)})`; // scrollTop + 多少根据自己的需求设置
      } else if (scrollTop === 0) {
        this.topNavBg.backgroundColor = "transparent"; // 设置回到顶部时，背景颜色为透明
      }
    },
    // 滚动之前重置
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>

<style lang="scss" scoped>
.official {
  position: relative;
}
.header-nav {
  // overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  height: 80px;
  display: flex;
  justify-content: center;
  line-height: 80px;
  font-size: 18px;
  color: #fff;

  .header-down {
    position: absolute;
    background: url("../../assets/navigation.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    left: 0;
    top: 80px;
    z-index: 1000;
    color: white;
    box-sizing: border-box;
    padding: 58px 320px;

    .down-conte {
      display: flex;
      justify-content: space-between;
      width: 1456px;
      margin: 0 auto;

      .down-title {
        display: flex;
        justify-content: left;
        align-items: center;
        color: #fff;
      }

      img {
        height: 24px;
        width: 24px;
        margin-right: 10px;
      }

      .down-txt {
        line-height: 50px;
        color: rgb(192, 193, 195);
      }

      .down-txt:hover {
        cursor: pointer;
        color: #056d73;
      }
    }

    .line {
      height: 2px;
      width: 100%;
      margin-bottom: 20px;
      background: linear-gradient(
        to right,
        rgb(192, 193, 195),
        rgb(32, 35, 46)
      );
    }
  }
}

.header-nav:hover {
  background: #242632 !important;
}

.header-logo {
  line-height: 80px;
  margin-top: 16px;
  width: 300px;
}

.header-img {
  height: 40px;
}

a {
  text-decoration: none;
  color: #fff;
}

.header-ul {
  display: flex;
  align-items: center;
  width: 856px;

  .header-li {
    padding: 0 40px;
    height: 100%;
    font-size: 18px;
    display: flex;
  }

  .header-li:hover {
    background: rgba(16, 140, 238, 1);
    cursor: pointer;
  }

  .header-li.active {
    background: rgba(16, 140, 238, 1);
  }
}

ul,
li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.carousel-img {
  position: relative;
  overflow: hidden;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    animation: zoomInOut 6s alternate infinite 1s; //图片缩放动画时间方法创建
    // cursor: pointer;
  }
  //图片缩放效果动画设置
  @keyframes zoomInOut {
    // 动效起点
    0% {
      transform: scale(1);
    }
    // 动效终点
    100% {
      transform: scale(1.06);
    }
  }
}

::v-deep .el-carousel__indicator {
  display: none;
}

.carousel-indicators {
  position: absolute;
  left: 15%;
  bottom: 18%;
  z-index: 5;
  text-align: center;
  margin-top: 20px;
  display: flex;
  height: 60px;
}
.carousel-indicators span {
  display: inline-block;
  margin: 0 20px;
  cursor: pointer;
  font-size: 36px;
  display: flex;
  color: #8b9099;
  transition: font-size 0.5s;
}
.carousel-indicators span.active {
  color: #056d73;
  font-size: 46px;
  line-height: 36px;
}

.line {
  width: 280px;
  height: 2px;
  border-radius: 5px;
  overflow: hidden; /* 确保进度条不超出容器范围 */
  display: none;
  background-color: rgba(8, 76, 77, 0.2);
  transform: translateY(20px);
}

.line.active {
  display: block;
  margin-left: -20px;
  margin-right: 10px;
}

.line-color {
  background-color: #056372;
  height: 100%;
  width: 0; /* 初始宽度为0 */
  // border-radius: 50%;
  animation: loadProgress 4s linear forwards; /* 应用动画 */
}

@keyframes loadProgress {
  to {
    width: 100%; /* 动画结束时宽度变为100% */
  }
}

// .header-txt {
//   position: absolute;
//   top: 50%;
//   left: 13%;
//   transform: translateY(-50%);

//   h3 {
//     color: #fff;
//     font-size: 2.7vw;
//     margin-bottom: -0.5vw;
//   }

//   p {
//     color: rgba(156, 156, 156, 1);
//     font-size: 0.9vw;
//   }

//   h4 {
//     color: #fff;
//     font-size: 1.35vw;
//     margin-bottom: 2vw;
//   }

//   .transparent-button {
//     background-color: transparent; /* 设置背景色为透明 */
//     border-color: #fff; /* 设置边框颜色为透明 */
//     color: #fff; /* 设置字体颜色为白色，可根据需要调整 */
//     opacity: 1; /* 设置透明度为 0.5，可根据需要调整 */
//   }
// }
</style>
